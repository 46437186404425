<template>
    <div>
        <div class="find-tour-results-footer ">
            <div class="text-center" >
                <button v-if="!showMoreButton" type="button" class="btn btn-primary"  @click="showMoreButton = true">Show all tours</button>
                <infinite-loading v-if="showMoreButton" @infinite="infiniteHandler" >
                    <div slot="spinner">
                        <div class="loading-spinner-wrapper">
                            <div class="loading-spinner"></div>
                        </div>
                    </div>
                    <div slot="no-more">No more tours</div>
                    <div slot="no-results">No more tours</div>
                </infinite-loading>
            </div>
        </div>
    </div>
</template>

<script>
    import InfiniteLoading from 'vue-infinite-loading';
    import {mapGetters} from 'vuex'
    export default {
        name: "ShowMore",
        data: () => ({
            limit: 6,
            limitStatic: 6,
            showMoreButton: false
        }),
        components: {
            InfiniteLoading,
        },
        computed: {
            ...mapGetters(['filteredTours'])
        },
        methods: {
            infiniteHandler($state) {
                setTimeout( () => {
                    if(this.limit < this.filteredTours.length ){
                        this.limit += this.limitStatic
                        $state.loaded()
                    }else{
                        $state.complete()
                    }
                    this.$emit('page', this.limit)
                }, 600)
            },
        },
    }
</script>

<style scoped>

</style>