import _ from "lodash";
import slugify from "slugify";
import {mapGetters} from "vuex";

export  default {
    data: () => ({

    }),
    computed: {
        ...mapGetters(['selectedFilter', 'ApiFilters', 'filteredTours', 'selectedFilterCount', 'selectedFilterDaterange']),
        selectedFilterNames()
        {
            let filters = this.selectedFilter
            let query = {}
            _.forEach(filters, (filterArray, filterKey) => {
                if(!filterArray.length) return
                query[filterKey] = _.filter(this.ApiFilters[filterKey], filterItem => filterArray.includes(filterItem.id))
            })
            return query
        }

    },
    methods: {
        resetFilters(){
            _.forEach(this.selectedFilter, (v, k) => {
                this.selectedFilter[k] = []
            })
            this.applyFilters()
            this.$router.push({query:[]}).catch( () => {})
        },
        applyFilterInput(){
            const route = this.applyFilters()
            this.$router.push({name: 'theDestination', query:route}).catch( () => {})
        },
        applyFilters(){
            let query = {}
            _.forEach(this.selectedFilter, (filterArray, filterKey) => {
                if(!filterArray.length) return
                query[filterKey] = _.filter(this.ApiFilters[filterKey], filterItem => filterArray.includes(filterItem.id)).map(s => slugify(s.name)).join()
            })
            this.$store.commit('commitSelectedFilter', this.selectedFilter)
            this.$store.commit('processFiltering')
            const dateRange = this.$store.getters.selectedFilterDaterange
            if(dateRange.startDate && dateRange.endDate){
                query['date-start'] = dateRange.startDate
                query['date-end'] = dateRange.endDate
            }
            return query
        },
        removeFilterItem(filterItem, type){
            if( typeof filterItem === 'object'){
                _.remove(this.selectedFilter[type] , arr => arr === filterItem.id)
            }
            if( typeof  filterItem === 'number'){
                _.remove(this.selectedFilter[type] , arr => arr === filterItem)
            }
            const route = this.applyFilters()
            this.$router.push({query:route}).catch( () => {})
        },
        initRoute(){
            let route = this.$route
            let selectedFilter = this.selectedFilter
            let filters = this.ApiFilters
            _.forEach(filters, (filterArray, filterKey) => {
                if(route.query[filterKey] && route.query[filterKey].length > 0 ){
                    const names = route.query[filterKey].split(',')
                    _.forEach(filters[filterKey], (theFilter) => {
                        if( names.includes( slugify(theFilter.name) ) ){
                            selectedFilter[filterKey].push(theFilter.id)
                        }
                    })
                }
            })
            this.$store.commit('commitSelectedFilter', selectedFilter)
            this.$store.commit('processFiltering')
        }
    },

}
