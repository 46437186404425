<template>
    <div>



        <div class="tour-filter-block aside daterange">
            <div class="filter-title">
                <div class="filter-title-link collapse-arrow" role="button" data-toggle="collapse"
                     data-target="#collapseVueDateRange"
                     aria-expanded="false" aria-controls="collapse_daterange_aside">
                  Travel dates
                </div>
            </div>
            <div class="filter-calendar  collapse in"  id="collapseVueDateRange">
                <vc-date-picker
                        v-model="picker"
                        is-range
                        :columns="2"
                        mode="date"
                        color="orange"
                >
                    <template v-slot="{ showPopover }">
                        <div class="filter-calendar-flex"
                             @click="showPopover"
                        >
                            <div class="filter-calendar-flex">
                                    <div class="filter-calendar-header">
                                        <div class="filter-calendar-header-item">
                                            <div class="year" v-if="date.start">
                                                {{date.start | theDate(format.year)}}
                                            </div>
                                            <div class="name" v-else>
                                                Start
                                            </div>
                                        </div>
                                        <div class="filter-calendar-header-item">
                                            <div class="filter-calendar-header-item">
                                                <div class="year" v-if="date.end">
                                                    {{date.end | theDate(format.year)}}
                                                </div>
                                                <div class="name" v-else>
                                                    End
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="filter-calendar-body">
                                        <div class="filter-calendar-body-item">
                                            <template v-if="date.start">
                                                <div class="day">
                                                    {{date.start | theDate(format.day)}}
                                                </div>
                                                <div class="month">
                                                    {{date.start | theDate(format.month)}}
                                                </div>
                                            </template>
                                            <template v-else>
                                                <div class="icon">
                                                    <i class="fa fa-calendar"></i>
                                                </div>
                                            </template>
                                        </div>
                                        <div class="filter-calendar-body-dot"></div>
                                        <div class="filter-calendar-body-item">
                                            <template v-if="date.end">
                                                <div class="day">
                                                    {{date.end | theDate(format.day)}}
                                                </div>
                                                <div class="month">
                                                    {{date.end | theDate(format.month)}}
                                                </div>
                                            </template>
                                            <template v-else>
                                                <div class="icon">
                                                    <i class="fa fa-calendar"></i>
                                                </div>
                                            </template>
                                        </div>
                                    </div>
                            </div>
                        </div>
                    </template>
                </vc-date-picker>
            </div>
        </div>
    </div>
</template>

<script>
    import moment from 'moment'
    import MixinFilters from "../../mixin/MixinFilters"
    import {mapGetters} from 'vuex'
    export default {
        name: "DateRange",
        mixins: [MixinFilters],
        data: () => ({
            format: {
                year: 'YYYY',
                month: 'MMM',
                day: 'DD',
                eras: 'YYYY-MM-DD'
            }
        }),
        computed: {
            ...mapGetters(['selectedFilterDaterange']),
            picker: {
                get: function() {
                    if(
                        this.selectedFilterDaterange.startDate &&
                        this.selectedFilterDaterange.endDate &&
                        moment(this.selectedFilterDaterange.startDate, this.format.eras).isValid() &&
                        moment(this.selectedFilterDaterange.endDate, this.format.eras).isValid()
                    ){
                        return {
                            start: moment(this.selectedFilterDaterange.startDate, this.format.eras).toDate(),
                            end: moment(this.selectedFilterDaterange.endDate, this.format.eras).toDate()
                        }
                    }
                    return {start: null, end: null}

                },
                async set(val) {
                    this.$store.commit('commitDaterange', {startDate: val.start, endDate: val.end })
                    try{
                        this.$emit('loading', true)
                        await this.$store.dispatch('daterange', {startDate: this.startDate, endDate: this.endDate})
                        this.applyFilterInput()
                    }catch (e) {
                        console.log(e)
                    }finally {
                        this.$emit('loading', false)
                    }
                }
            },
            date(){
                const start = this.selectedFilterDaterange && this.selectedFilterDaterange.startDate ? this.selectedFilterDaterange.startDate : null
                const end = this.selectedFilterDaterange && this.selectedFilterDaterange.endDate ? this.selectedFilterDaterange.endDate : null
                return {start, end}
            },
            startDate(){
                return this.picker.start ? moment(this.picker.start).format(this.format.eras) : null
            },
            endDate(){
                return this.picker.end ? moment(this.picker.end).format(this.format.eras) : null
            }
        },
        filters: {
            theDate(date, format){
                return moment(date).isValid() ? moment(date).format(format) : null
            }

        },
        async beforeMount() {
            const start = this.$route.query['date-start']
            const end = this.$route.query['date-end']
            if(start && end && moment(start).isValid() && moment(end).isValid()){
                this.picker = {start: moment(start).toDate(), end: moment(end).toDate()}
            }

        }
    }
</script>

<style  lang="sass">
    .filter-calendar
        font-size: 16px
        margin-top: 18px
        &-flex
            display: flex
            justify-content: center
            border-radius: 6px
            overflow: hidden
            flex-wrap: wrap
            width: 100%
            margin: 0 auto
            cursor: pointer
            &:hover
                opacity: 0.9
        &-header
            background: #fd4f00
            color: #fff
            flex: 1 0 100%
            display: flex
            &-item
                flex: 1 0 50%
        &-body
            background: #f2f2f2
            padding: 8px 3px
            flex: 1 0 100%
            display: flex
            &-item
                flex: 1 0 auto
            &-dot
                flex: 0 0 8px
                height: 8px
                background: #55637d
                align-self: center
                border-radius: 50%
        .day
            text-align: center
            font-size: 1.5em
            line-height: 1
        .month
            text-align: center
            text-transform: uppercase
            font-size: 1.1em
        .year,
        .name
            text-transform: uppercase
            text-align: center
            font-size: 1em
            line-height: 28px
            letter-spacing: 0.1ch
        .icon
            font-size: 2em
            text-align: center
</style>
