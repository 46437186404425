import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/our-tours/',
        name: 'ourTours'
    },
    {
        path: '/our-tours/:destination/:alias',
        name: 'theTour'
    },
    {
        path: '/our-tours/:destination',
        name: 'theDestination'
    },


]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
