<template>
        <div class="td-tours" id="adventure" >



            <div class="td-tours-background">
                <div class="td-tours-background-image">
                    <div class="container">

                        <div>
                            <h2 class="title-underline">
                                The most popular Italian attractions
                            </h2>
                        </div>

                        <div class="td-tours-sections">

                            <CreateYourExperienceIcons3D />

                            <CreateYourExperienceCalendar />

                        </div>

                    </div>
                    <div class="td-tours-actions">
                        <div class="text-center">
                            <button v-if="isButton" type="submit" class="btn btn-lg btn-bardo" @click="find">Find tours</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
</template>

<script>
    import {mapGetters} from 'vuex'
    import CreateYourExperienceIcons3D from "./components/CreateYourExperience/CreateYourExperienceIcons3D";
    import CreateYourExperienceCalendar from "./components/CreateYourExperience/CreateYourExperienceCalendar";
    export default {
        name: "CreateYourExperienceApp",
        components: {CreateYourExperienceCalendar, CreateYourExperienceIcons3D},
        data: () => ({
            loading: true
        }),
        methods:{
            find(){
                this.$store.dispatch('createExperience/pushFind')
            }
        },
        computed: {
            ...mapGetters('createExperience', ['attractions']),
            isButton()
            {
                return this.attractions.length > 0
            }

        },
        async created()
        {
            await this.$store.dispatch( 'createExperience/getAttractions')
        }
    }
</script>

<style scoped lang="sass">
.td-tours
    position: relative
    &-actions
        height: 50px
        position: sticky
        bottom: 15px
</style>