<template>
    <div>
        <SearchWizardModal/>
    </div>
</template>

<script>
    import SearchWizardModal from "./components/Filters/SearchWizardModal";
    import MixinFilters from "./mixin/MixinFilters";

    export default {
        name: 'VueSearchWizard',
        mixins: [MixinFilters],

        components: {
            SearchWizardModal
        },
        async beforeCreate() {
            await this.$store.dispatch('tours_wizard', {...this.$route.params})
            this.initRoute()
        }
    }
</script>

<style>

</style>
