<template>
    <section class="td-tours-sections-calendar carousel slide" id="carousel_icons_of_years" data-ride="carousel" data-interval="false">
        <div class="title">
            <h3 class="title">
                When do you want to go?
            </h3>
        </div>
        <div class="destinations">
            <div class="title-indicators buttons">
                <button type="button" class="btn btn-link buttons-btn" @click="changeYear(-1)">
                    <i class="fa fa-caret-left" aria-hidden="true"></i>
                    <span class="buttons-date">
                        <span>{{btnTextPrev.format('MMM')}}</span>
                        <span>{{btnTextPrev.format('YYYY')}}</span>
                    </span>
                </button>
                {{dateRange}}
                <button type="button" class="btn btn-link buttons-btn" @click="changeYear(1)">
                    <span class="buttons-date">
                        <span>{{btnTextNext.format('MMM')}}</span>
                        <span>{{btnTextNext.format('YYYY')}}</span>
                    </span>
                     <i class="fa fa-caret-right" aria-hidden="true"></i>
                </button>

            </div>
        </div>
        <div class="calendar">
            <MonthCalendar :months="momentMonths" />
        </div>
        <div>
        </div>

    </section>



</template>

<script>
    const inputs = 'quarter'
    import moment from "moment";
    import {mapGetters} from 'vuex'
    import MonthCalendar from "./MonthCalendar";
    export default {
        name: "CreateYourExperienceCalendar",
        components: {MonthCalendar},
        data: () => ({
            curMoment: null
        }),
        computed: {
            ...mapGetters('createExperience', ['momentDate']),
            momentMonths(){
                const months = []
                const firstQmonth  = this.curMoment instanceof moment ? this.curMoment : moment().startOf('quarter')
                for(let i = 0; i < 12; i++){
                    months.push(firstQmonth.clone())
                    firstQmonth.add(1, 'month')
                }
                return months
            },
            dateRange(){
                const dateFormat = 'MMM YYYY'
                const start =  this.momentDate.start instanceof moment ? this.momentDate.start.format(dateFormat) : null
                const end =  this.momentDate.end instanceof moment ? this.momentDate.end.format(dateFormat) : null
                return (start && end && start !== end) ? `${start} - ${end}` : (start) ? start : null
            },
            btnTextNext(){
                const moment = this.momentMonths.slice(-1)[0].clone()
                return moment.add(1, inputs).endOf(inputs)
            },
            btnTextPrev(){
                const moment = this.momentMonths[0].clone()
                return moment.add(-1, inputs).startOf(inputs)
            }
        },
        methods: {
            changeYear(change = 1){
                const firstDate = this.momentMonths[0].clone()
                if(firstDate < moment().startOf(inputs) && change < 0){
                    return
                }
                this.curMoment = firstDate.add(change, inputs ).startOf(inputs)
            },

        },
        filters: {
            formatDate(v){
                return moment(v, '')
            }
        }
    }
</script>

<style scoped>

</style>