<template>
    <div v-if="selectedGuideObject && isOpen" class="mini-guide" :class="{'not-available': !isAvailable}">
        <div class="clearfix">
            <div class="pull-left">
                <div class="avatar">
                    <img :alt="selectedGuideObject.name" width="120" :src="selectedGuideObject.avatar" class="img-thumbnail">
                </div>
                <div>
                    <button class="btn btn-danger-outlined hidden-lg btn-block" @click.prevent="selectGuide(null)">DECLINE</button>
                </div>
            </div>
            <div class="content">
                <template v-if="isAvailable">
                    <div class="header">
                        <div class="h4"><strong>{{selectedGuideObject.name}}</strong> is your booked guide.</div>
                        <div class="removeGuide">
                            <button class="btn btn-book-decline hidden-xs hidden-sm hidden-md" @click.prevent="selectGuide(null)"></button>
                        </div> 
                    </div>

                    <div v-if="languages">
                        <span>Spoken languages: </span>
                        <span v-html="languages"></span>
                    </div>
                </template>
                <template v-else>
                    <div class="alert alert-warning" role="alert">
                        <button  @click="closeAlert" type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                        <span> <strong>{{selectedGuideObject.name}}</strong> is not available on this destination!</span>
                    </div>
                </template>
                <div>
                    <span>Available in: </span>
                    <span v-html="destinations"></span>
                </div>

                
            </div>
        </div>

    </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from 'vuex'
export default {
    name: "MiniGuide",
    data: () => ({
        isOpen: true
    }),
    methods: {
        ...mapActions('guides', ['fetchGuides']),
        ...mapMutations('guides', ['selectGuide']),
        closeAlert()
        {
            this.isOpen = false
        }
    },
    computed: {
        ...mapGetters('guides', ['selectedGuide', 'selectedGuideObject']),
        isAvailable(){
            return this.$route.params.destination === 'rome-vatican'
        },
        languages(){
            return this.selectedGuideObject ? this.selectedGuideObject.languages.map( l => `<em class="text-underlined">${l}</em>`).join(', ' ) : undefined
        },
        destinations(){
            return this.selectedGuideObject ? this.selectedGuideObject.destinations.map( d => `<em class="text-underlined">${d}</em>`).join(', ') : undefined
        }
    },
    created(){
        if(this.selectedGuide){
            this.fetchGuides()
        }
    }
}
</script>

<style scoped lang="sass">
.mini-guide
    margin-bottom: 34px
    position: relative
.content
    margin-left: 144px

.not-available
    .avatar
        opacity: 0.5
.removeGuide
    position: absolute
    right: 0
    top: 0
.close
    font-size: 32px
    line-height: 21px
    
    
.btn-book-decline
    width: 120px
    &:before
        content: 'BOOKED'
    &:hover
        background: 
        &:before
            content: 'DECLINE'
    
</style>
