<template>
    <div class="icons-item" @click="selectIcon" :class="{'selected': isSelected, 'disabled': !isEnabled }">
        <div class="icons-location">{{item.city}}</div>
        <div class="icons-image">
            <img class="icons-src" :src="item.image_grayscale_path" :alt="item.name_icon">
            <img class="icons-src-hover" :src="item.image_name_path" :alt="item.name_icon">
        </div>
        <div class="icons-title">
            {{item.name_icon}}
        </div>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex'

    export default {
        props: ['item'],
        name: "AttractionIcon",
        data: () => ({
           chosen: false
        }),
        computed: {
            ...mapGetters('createExperience', ['icons', 'attractions']),
            isSelected() {
                const id = this.item.id
                return this.attractions.length > 0 ?
                    this.attractions.findIndex(v => v.id === id) !== -1 :
                    false
            },
            isEnabled()
            {
                const city_alias = this.item.city_alias
                return this.attractions.length > 0 ?
                    this.attractions.findIndex( v => v.city_alias === city_alias) !== -1 :
                    true
            }
        },
        methods: {
            selectIcon() {
                this.chosen = !this.chosen
                this.$store.commit('createExperience/commitAttractions', this.item)
            }
        },
    }
</script>

<style scoped>

</style>