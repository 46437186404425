import axios from 'axios'
import slugify from "slugify"
const dateFormat = 'YYYY-MM-DD'
export default {
    namespaced: true, // createExperience
    state: {
        icons: [],
        date: {
            'date-start': null,
            'date-end': null
        },
        momentDate: {
            start: null,
            end: null
        },
        attractions: []
    },
    actions: {
        getAttractions({commit}) {
            return axios.get(process.env.VUE_APP_HOST + '?option=com_rezdyinfo&task=vue.create_your_experience&format=json')
                .then(res => {
                    commit('commitIcons', res.data.data.icons)
                    return true
                })
                .catch(() => {
                    return false
                })
        },
        pushFind({state}){
            const params = []
            let destination = 'rome'
            if(state.momentDate.start && state.momentDate.end){
                params.push('date-start=' + state.momentDate.start.format(dateFormat) + '&date-end=' + state.momentDate.end.format(dateFormat))
            }
            if(state.attractions.length){
                params.push('itinerary=' + state.attractions.map( v => {return slugify(v.itinerary_alias)}).join(','))
                const destination_index = state.attractions.findIndex( v => v.city_alias !== null && v.city_alias.length)
                destination =  destination_index !== -1 ? state.attractions[destination_index]['city_alias'] : destination
            }
            window.location.href = process.env.VUE_APP_HOST + 'our-tours/' + destination + '?' + params.join('&')
        }
    },
    mutations: {
        commitIcons(state, payload) {
            state.icons = payload
        },
        commitMomentDate(state, payload) {
            state.momentDate = payload
        },
        commitAttractions(state, payload) {
            const theIndex = state.attractions.findIndex( v => v.id === payload.id)
            if(theIndex !== -1 ){
                state.attractions.splice(theIndex, 1)
                return
            }
            const attractions = state.attractions.filter(v => v.city_alias === payload.city_alias)
            attractions.push(payload)
            state.attractions = attractions
        }
    },
    getters: {
        date: s => s.date,
        momentDate: s => s.momentDate,
        icons: s => s.icons,
        attractions: s => s.attractions
    }
}