<template>

    <div class="dropdown">
        <button class="btn btn-default dropdown-toggle" type="button" id="tour_sorting_by" data-toggle="dropdown"
                aria-haspopup="true" aria-expanded="true">
            Order by: {{orderingValue.text}}
            <span class="caret"></span>
        </button>
        <ul class="dropdown-menu" aria-labelledby="tour_sorting_by">
            <li class="dropdown-item"
                v-for="(button, index) in dropDownProps"
                :key="index"
            >
                <a href="#"
                   @click.prevent="changeOrdering(button)"
                >{{button.text}}</a>
            </li>
        </ul>
    </div>

</template>

<script>
    import {mapMutations} from 'vuex'

    export default {
        name: "OrderDropdown",
        data: () => ({
            orderingValue: {
                key: ['num_ratings', 'badges.badge_power', 'average_rating_round', 'questions'],
                order: ['desc', 'desc', 'desc', 'desc'],
                text: 'Popularity'
            },
            dropDownProps: [
                {
                    key: ['num_ratings', 'badges.badge_power', 'average_rating_round', 'questions'],
                    order: ['desc', 'desc', 'desc', 'desc'],
                    text: 'Popularity'
                },
                {
                    key: ['average_rating_round', 'badges.badge_power', 'num_ratings', 'questions'],
                    order: ['desc', 'desc', 'desc', 'desc'],
                    text: 'Rating'
                },
                {
                    key: ['eras.duration', 'badges.badge_power', 'average_rating_round', 'num_ratings'],
                    order: ['asc', 'desc', 'desc', 'desc'],
                    text: 'Duration'
                },
                {
                    key: ['eras.advertisedPrice', 'badges.badge_power', 'average_rating_round', 'num_ratings'],
                    order: ['asc', 'desc', 'desc', 'desc'],
                    text: 'Price (from low to high)'
                },
                {
                    key: ['eras.advertisedPrice', 'badges.badge_power', 'average_rating_round', 'num_ratings'],
                    order: ['desc', 'desc', 'desc', 'desc'],
                    text: 'Price (from high to low)'
                },
                {
                    key: ['alias', 'badges.badge_power', 'average_rating_round', 'num_ratings'],
                    order: ['desc', 'desc', 'desc', 'desc'],
                    text: 'Name'
                }
            ]
        }),
        methods: {
            ...mapMutations(['commitOrdering']),
            changeOrdering(button) {
                this.orderingValue = button
                this.commitOrdering(button)
            }
        },
        created() {
            this.changeOrdering(this.orderingValue)
        }
    }
</script>

<style scoped>

</style>
