<template>
    <div class="filters-legend">
        <div v-for="filterKey in filtersList" :key="filterKey" class="filters-legend-box" >
            
            <div class="filters-legend-label">
                {{filtersMap[filterKey]}}:
            </div>
            
            <ul
                class="filters-legend-flex"
                :class="`filters-${filterKey}`"
            >
                <li
                    class="filters-legend-flex-item"
                    v-for="filter in ApiFilters[filterKey]"
                    :key="filter.id"
                > 
                        <input 
                            type="checkbox"
                            class="hidden filters-legend-checkbox"
                            :id="`filter_${filterKey}_${filter.id}`"
                            v-model="selectedFilter[filterKey]"
                            :value="filter.id"
                            @change="applyFilterInput"
                            :disabled="filter.count === 0 && !selectedFilter[filterKey].includes(filter.id)"
                        >
                        <label :for="`filter_${filterKey}_${filter.id}`" class="filters-legend-item small"  :class="{ 'item-destinations': filterKey === 'destinations' }">
                                <div v-if="filter.icon" class="filters-legend-item-icon">
                                    <i class="filter-art-icon" :class="filter.icon"></i>
                                </div>
                                <div class="filters-legend-item-label">{{ filter.name }}</div>
                                <div class="filters-legend-item-status">
                                    <div v-if="filter.count > 0" class="filters-count">
                                        {{ filter.count }}
                                    </div>
                                    <a v-if="selectedFilter[filterKey].includes(filter.id)" type="button"  class="filters-close">
                                        <i class="fa fa-times-circle"></i>
                                    </a>
                                </div>
                        </label>


                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import MixinFilters from "@/mixin/MixinFilters";
export default {
    name: "OurToursSearchWizardFilters",
    mixins: [MixinFilters],
    data: () => ({
        filtersList: ['destinations', 'focus', 'tags',  'interestsTags'],
    }),
    computed: {
        ...mapGetters(['ApiFilters', 'selectedFilter', 'filtersMap'])
    }
}
</script>

<style scoped lang="sass">
.filters-legend
    &-box 
        display: flex
        align-items: baseline
        margin: 8px 0
    &-label
        flex: 0 0 150px
        font-size: 18px
</style>
