<template>
        <div class="mod_eras_find_tour find-tour module tour-container-wrapper">
    
            <div class="tour-container-wrapper-header" id="vue_grid_destination">
                <div class="tour-container-wrapper-header-favorite">
                    <FavoriteFilters />
                </div>
                <div class="container">
                    <div class="tour-container-wrapper-header-flex">
                        <div>
                            <LegendDestination />
                        </div>
                        <div class="tour-container-wrapper-header-controls">
                            <RestFilters />
                            <OrderDropdown />
                        </div>
                    </div>
                </div>
            </div>
            <div class="container">
                <div class="tour-container-wrapper-body">
                    <aside class="tour-filters for-destination" :class="openMobileClass">
                        <div class="filter-modal-nav ">
                            <div class="filter-modal-nav-back">
                                <a href="#" class="bnt btn-link FilterMenu" @click.prevent="openMobile">
                                    <i class="fa fa-angle-left " aria-hidden="true"></i>
                                    <span>FILTERS</span>
                                </a>
                            </div>
                            <div class="filter-modal-nav-clear" >
                                <span class="FilterMenu btn btn-default-outlined" :class="{'disabled': (this.selectedFilterCount === 0)}" @click.prevent="resetFilters" role="button" tabindex="0">RESET</span>
                            </div>
                        </div>
                        <div class="tour-filters-wrapper">
                            <div class="tour-filters-subhead" >
                                <CounterOfTours />
                            </div>
                            <DateRange @loading="emitLoading" v-if="requests.tours_wizard_success" />
                            <FiltersDestination />
                        </div>
                        <div class="invoke-filter-modal">
                            <button class="FilterMenu btn btn-small" :class="selectedFilterCount > 0 ? openMobileClass : null" @click.prevent="openMobile"><i class="fa fa-sliders"></i> <span
                                class="filter-text ">{{FilterMenuButtonText}}</span> <span class="foundFilters" aria-hidden="true">{{selectedFilterCount}}</span>
                            </button>
                        </div>
                    </aside>

                    <main class="destination-tours">
                        <div class="destination-tours-header">
                            <MiniGuide />
                        </div>
                        <div class="find-tour-results" v-if="requests.tours_wizard_success && !loading">
                            <DestinationTours />
                        </div>
                        <div class="loading-spinner-wrapper" v-else>
                            <div class="loading-spinner"></div>
                        </div>
                    </main>
                </div>
            </div>
            
        </div>

</template>

<script>
    import LegendDestination from "./components/Legend/LegendDestination";
    import DestinationTours from "./components/Tours/DestinationTours";
    import {mapGetters} from 'vuex'
    import FiltersDestination from "./components/Filters/FiltersDestination";
    import OrderDropdown from "./components/Filters/OrderDropdown";
    import MixinFilters from "./mixin/MixinFilters";
    import DateRange from "./components/TourModules/DateRange";
    import RestFilters from "@/components/Filters/RestFilters";
    import CounterOfTours from "@/components/TourModules/CounterOfTours";
    import FavoriteFilters from "@/components/TourModules/FavoriteFilters";
    import MiniGuide from "./components/Guides/MiniGuide";

    export default {
        name: "TourDestinationApp",
        data: () => ({
            limit:  12,
            openMobileClass: null,
            loading: false
        }),
        mixins: [MixinFilters],
        components: {
            MiniGuide,
            FavoriteFilters,
            CounterOfTours,
            RestFilters,
            DateRange,
            OrderDropdown,
            FiltersDestination,
            DestinationTours,
            LegendDestination,
        },
        methods: {
            showMorePage(page){
                this.limit = page
            },
            openMobile() {
                this.openMobileClass = this.openMobileClass ? null : 'open'
            },
            emitLoading(status){
                this.loading = status
            }
        },
        computed: {
            ...mapGetters(['requests']),
            FilterMenuButtonText() {
                return this.openMobileClass === 'open' ?
                    this.selectedFilterCount === 0 ?
                        'Close' :
                        'Apply filters' :
                        'Filters'
            }
        },
        created() {
            // if(this.$route.name === 'theDestination' && this.$router.params.destination ) { }
        },
        async beforeCreate() {
            await this.$store.dispatch('tours_wizard', {...this.$route.params})
            this.initRoute()
        }
    }
</script>
