<template>



    <div class="modal-content">
        <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
            <div class="h4 modal-title">{{headTitle}}</div>
        </div>
        <div class="modal-body">

            <div class="find-tour">
                <div class="tour-tab wizard">
                    <ul class="tour-tab-list nav">
                        <li
                            v-for="(filterName, index) in filtersList" :key="index"
                            class="tour-tab-item"
                            :class="currentFilter === filterName ? 'active' : ''"
                        >
                            <a type="button" class="title"  @click.prevent="currentFilter = filterName">{{filtersMap[filterName]}}</a>
                        </li>
                    </ul>
                </div>


                <div class="tab-content1">
                    <div v-for="filterKey in filtersList" :key="filterKey" class="tab-pane1">
                        <ul
                            v-if="currentFilter === filterKey"
                            class="filter-list"
                            :class="`filter-${filterKey}`"
                        >
                            <li
                                v-for="filter in ApiFilters[filterKey]"
                                :key="filter.id"
                            >
                                <input
                                    class="hidden"
                                    type="checkbox"
                                    :id="`filter_${filterKey}_${filter.id}`"
                                    v-model="selectedFilter[filterKey]"
                                    :value="filter.id"
                                    @change="applyFilters"
                                    :disabled="filter.count === 0 && !selectedFilter[filterKey].includes(filter.id)"
                                >
                                <label :for="`filter_${filterKey}_${filter.id}`">
                                    <div v-if="filter.icon">
                                        <i class="filter-art-icon" :class="filter.icon"></i>
                                    </div>
                                    <div>
                                        <span>{{filter.name}}</span>
                                    </div>
                                    <div v-if="filter.count > 0" class="filter-count">
                                        {{filter.count}}
                                    </div>
                                </label>

                            </li>
                        </ul>
                    </div>
                </div>


                <div class="modal-bottom">

                    <div class="we-found" v-if="filteredTours.length > 0" aria-hidden="false">
                        <div class="h4">We found for you <span class="we-found-badge">{{filteredTours.length}}</span> tours</div>
                    </div>



                    <div class="modal-footer-nav" >
                        <div class="btn-box">
                            <button v-if="navigationPrev === 'reset'" class="btn btn-default" @click="resetFilters" type="button">Reset</button>
                            <button v-else class="btn btn-default" @click.prevent="prev" type="button">Back</button>

                        </div>
                        <div class="btn-box">
                            <button  v-if="navigationNext === 'apply'" @click="apply" class="btn btn-booking" data-dismiss="modal" aria-label="Close" type="button">Apply</button>
                            <button  v-else class="btn btn-primary" @click="next" type="button" >Next</button>
                        </div>
                    </div>

                </div>
            </div>
            
            
        </div>
    </div>
    

    


</template>

<script>
    import {mapGetters} from 'vuex'
    import _ from 'lodash'
    import MixinFilters from "../../mixin/MixinFilters";
    export default {
        name: "SearchWizardModal",
        mixins: [MixinFilters],
        data: () => ({
            filtersList: ['destinations', 'focus', 'tags',  'interestsTags'],
            currentFilter: 'destinations'
        }),
        computed: {
            ...mapGetters(['ApiFilters', 'ApiTours', 'filteredTours', 'filtersMap', 'selectedFilter']),
            headTitle(){
                const isDestination = this.$route.name === 'theDestination' 
                if(isDestination && this.ApiFilters.destinations){
                    const destinations = _.find(this.ApiFilters.destinations, s => this.$route.params.destination === s.alias)
                    if(destinations){
                        return 'Find your tour in ' + destinations.name
                    }
                }
                return 'Find your tour'
            },
            navigationPrev(){
                if(this.filtersList.findIndex( s => s === this.currentFilter) === 0){
                    return 'reset'
                }
                return 'back';
            },
            navigationNext(){
                if(this.filtersList.findIndex(s => s === this.currentFilter) === this.filtersList.length - 1){
                    return 'apply'
                }
                return 'next'
            }
        },
        methods: {
            apply(){
                let query = this.applyFilters()
                const destination = this.selectedFilter.destinations.length === 1 ? this.ApiFilters.destinations[this.selectedFilter.destinations[0]] : false
                if(destination){
                    this.$router.push({name: 'theDestination', params: {destination: destination.alias}, query: query})
                    this.$router.go()
                    return
                }
                if(this.$route.name !== 'ourTours') {
                    this.$router.push({name: 'ourTours', query:query})
                    this.$router.go()
                    return
                }else{
                    this.$router.push({name: 'ourTours', query:query})
                }
            },
            next(){
                setTimeout(() => {
                    const curIndex = this.filtersList.findIndex(s => s === this.currentFilter)
                    if (this.filtersList.length - 1 > curIndex)
                    {
                        this.currentFilter = this.filtersList[curIndex+1]
                    }
                }, 1)
            },
            prev(){
                const curIndex = this.filtersList.findIndex(s => s === this.currentFilter)
                curIndex === 0 ? this.resetFilters() : this.currentFilter = this.filtersList[curIndex-1]
            }
        },
        created() {
            const isDestination = this.$route.name === 'theDestination'
            if(isDestination){
                this.currentFilter = 'focus'
                this.filtersList.shift()
            }
        }
    }
</script>

<style scoped>
    input[type=checkbox]:checked + label .filter-count{
        display: none;
    }
    .find-tour{
        min-height: 400px;
        display: flex;
        flex-direction: column;
    }
    .modal-bottom {
        margin-top: auto;
    }
</style>
