<template>
        <div>
            <div class="tour-filter tour-filter-block aside" :class="filterKey" v-for="(filterKey) in filtersListArray" :key="filterKey">
                <div class="filter-title">
                    <div class="filter-title-link collapse-arrow" role="button" data-toggle="collapse"
                         :data-target='`#collapse_${filterKey}_aside`'
                         aria-expanded="false" :aria-controls="`collapse_${filterKey}_aside`">
                        {{filtersMap[filterKey]}}
                    </div>
                </div>

                <ul class="filter-container collapse in" :id="`collapse_${filterKey}_aside`">
                    <li v-for="(filter, index) in ApiFiltersByKey(filterKey)" :key="`filter_destination_${filterKey}_${index}`">
                        <input
                                class="hidden"
                                type="checkbox"
                                :id="`filter_${filterKey}_${filter.id}`"
                                v-model="selectedFilter[filterKey]"
                                :value="filter.id"
                                @change="applyFilterInputTop"
                                :disabled="filter.count === 0 && !selectedFilter[filterKey].includes(filter.id)"
                        >

                        <label
                                :for="`filter_${filterKey}_${filter.id}`"
                                :class="`${filterKey}-${filter.id}`"
                        >
                            <div><i class="filter-art-icon" :class="filter.icon"></i></div>
                            <div><span>{{filter.name}}</span></div>
                            <div v-if="filter.count > 0" class="filter-count" aria-hidden="false">
                                {{filter.count}}
                            </div>
                        </label>
                    </li>
                    <li class="show-all" v-if="filtersListHideItems[filterKey] === true">
                        <button class="btn btn-link" @click="filtersListHideItems[filterKey] = false">Show all items</button>
                    </li>
                </ul>
            </div>
        </div>



</template>

<script>
    import {mapGetters, mapMutations} from 'vuex'
    import MixinFilters from "../../mixin/MixinFilters"
    import FilterOptions from "@/filters/FilterOptions";
    import _ from 'lodash'
    export default {
        name: "FiltersDestination",
        mixins: [MixinFilters],
        data: () => ({
            filtersList: ['focus', 'tags', 'interestsTags', 'categoriesIds', 'itinerary'],
            filtersListHideItems: {
                itinerary: true
            }
        }),
        computed: {
            ...mapGetters(['ApiFilters', 'filtersMap']),
            filtersListArray(){
                return this.filtersList.filter( v => _.size(this.ApiFilters[v]) > 0)
            }
        },
        methods: {
            ...mapMutations(['commitSelectedFilter']),
            ApiFiltersByKey(filterKey){
                if(filterKey === 'itinerary' && this.filtersListHideItems['itinerary'] === true){
                    return FilterOptions(this.ApiFilters[filterKey], 20)
                }
                return  this.ApiFilters[filterKey]
            },
            applyFilterInputTop(){
                const el = document.getElementById('vue_grid_destination');
                el && el.scrollIntoView();
                this.applyFilterInput()
            }
        },
        filters: {
            FilterOptions
        }


    }
</script>

<style scoped>
    input[type=checkbox]:checked + label .filter-count {
        display: none;
    }
    .show-all{
        display: block;
        flex: 0  0 100%;
    }
    .show-all .btn{
        color: #fd4f00;
    }
    .show-all .btn:after{
        content: '';
        display: block;
        border-bottom: 1px dotted #fd4f00;
    }
</style>
