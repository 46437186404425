import Vue from 'vue'
import VueSearchWizard from './VueSearchWizard.vue'
import ToursApp from './ToursApp'
import ToursDestinationApp from './ToursDestinationApp'
import CreateYourExperienceApp from './CreateYourExperienceApp'

import VCalendar from 'v-calendar';
Vue.use(VCalendar, {
    componentPrefix: 'vc',
});


import store from './store'
import router from './router'
Vue.config.productionTip = false

import TourSortingFilter from './filters/TourSortingFilter'
Vue.filter('TourSortingFilter', TourSortingFilter)



if(document.getElementById("VueSearchWizard")){
    new Vue({
        store,
        router,
        render: h => h(VueSearchWizard),
    }).$mount('#VueSearchWizard')
}


if(document.getElementById("VueTours")) {
    new Vue({
        store,
        router,
        render: h => h(ToursApp),
    }).$mount('#VueTours')
}

if(document.getElementById("VueToursDestination")) {
    new Vue({
        store,
        router,
        render: h => h(ToursDestinationApp),
    }).$mount('#VueToursDestination')
}

if(document.getElementById("VueCreateYourExperience")) {
    new Vue({
        store,
        router,
        render: h => h(CreateYourExperienceApp),
    }).$mount('#VueCreateYourExperience')
}

