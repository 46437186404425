import axios from "axios";
export default {
    namespaced: true,
    state: {
        selectedGuide:  localStorage.getItem('eras_booking_guide') !== null || localStorage.getItem('eras_booking_guide') !== 'null' ? localStorage.getItem('eras_booking_guide') : null,
        guides: [],
    },
    actions: {
        fetchGuides({commit, getters}){
            if(getters.guides.length > 0) return getters.guides
            return axios.get(process.env.VUE_APP_HOST + '?option=com_rezdyinfo&format=json&task=api.guides')
                .then( res => {
                    if(res.data.success !== true) throw new Error(res.data.message)
                    commit('commitGuides', res.data.data)
                    return getters.guides
                })
                .catch( err => {
                    console.error( err )
                })
        }
    },
    mutations: {
        /**
         * 
         * @param state
         * @param {String} payload
         */
        selectGuide(state, payload) {
            if(payload === null){
                localStorage.removeItem('eras_booking_guide')
                state.selectedGuide = null
                return
            }
            state.selectedGuide = payload
            localStorage.setItem('eras_booking_guide', payload)
        },
        commitGuides(state, payload){
            state.guides = payload
        }
        
    },

    getters: {
        selectedGuide: s => s.selectedGuide,
        selectedGuideObject: s => s.guides.length ? s.guides.find( g => g.username === s.selectedGuide) : undefined,
        guides: s => s.guides,
    }
}
/**
 * @param state
 * @param {Object | null} payload
 * @param {String} payload.name
 * @param {String} payload.username
 * @param {String} payload.alias
 * @param {String} payload.nationality
 * @param {String} payload.studies
 * @param {String} payload.born
 * @param {String} payload.avatar
 * @param {String} payload.author
 * @param {String} payload.guide-focus
 * @param {Array} payload.languages
 * @param {Array} payload.tours-eras
 * @param {Array} payload.guide-focus
 * @param {Array} payload.guide-destinations
 */
