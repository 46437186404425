<template>
    <div  v-if="filteredTours.length > 0">
        <span class="asButton">
            <strong>{{filteredTours.length}}</strong> Tours Available
        </span>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
    name: "CounterOfTours",
    computed: {
        ...mapGetters(['filteredTours'])
    }
}
</script>

<style scoped lang="sass">
    .asButton
        line-height: 34px
</style>
