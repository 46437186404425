<template>
    <ul class="search-legend-list">
        <li >
            <CounterOfTours />
        </li>

        <li v-if="dateRange" >
            <div class="search-legend-box">
                <span>
                    <i class="fa fa-calendar"></i>
                    {{dateRange}}
                </span>
                <a  type="button" @click.prevent="deleteDateRange" class="fa fa-times-circle close-filter"></a>
            </div>
        </li>
        <template>
            <template
                v-for="(v, type) in selectedFilter"
            >
                <li v-for="(filterID, index) in selectedFilter[type]" :key="`legend_destination_${type}_${index}`">
                    <div class="search-legend-box">
                    <span>
                        {{ApiFilters[type][filterID].name}}
                    </span>
                        <a  type="button" @click.prevent="removeFilterItem(filterID, type)" class="fa fa-times-circle close-filter"></a>
                    </div>
                </li>
            </template>
        </template>
        
    </ul>
</template>

<script>
    import {mapGetters, mapMutations} from 'vuex'
    import MixinFilters from "../../mixin/MixinFilters"
    import moment from "moment";
    import CounterOfTours from "@/components/TourModules/CounterOfTours";
    export default {
        name: "LegendDestination",
        components: {CounterOfTours},
        mixins: [MixinFilters],
        data: () => ({
            onlyFiltersList: ['focus', 'tags', 'interestsTags'],
        }),
        computed: {
            ...mapGetters(['ApiFilters', 'selectedFilter', 'selectedFilterDaterange']),
            onlyFilters()
            {
                return this.selectedFilterNames
            },
            dateRange()
            {
                const format = 'DD MMM Y'
                if(
                    this.selectedFilterDaterange.startDate &&
                    this.selectedFilterDaterange.endDate &&
                    moment(this.selectedFilterDaterange.startDate).isValid() &&
                    moment(this.selectedFilterDaterange.endDate).isValid()
                ){
                    return  moment(this.selectedFilterDaterange.startDate).format(format) + ' - ' + moment(this.selectedFilterDaterange.endDate).format(format)
                }
                return  null

            }

        },
        methods: {
            ...mapMutations(['commitSelectedFilter', 'commitDaterange', 'processFiltering']),
            deleteDateRange()
            {
                this.commitDaterange(false)
                this.processFiltering()
                this.applyFilterInput()
            }
        }
    }
</script>

<style scoped>
    input[type=checkbox]:checked + label .filter-count {
        display: none;
    }
</style>
