<template>
    <div class="tour-filter">
        <div class="container">
            <ul class="search-legend-list search-legend-list-favorite">
                <li>
                    Our top filters:
                </li>
                <template v-for="(_, filterKey) in filtersListArray" >
    
                    <li v-for="(filter, index) in filtersListArray[filterKey]" :key="`filter_favorite_${filterKey}_${index}`">
                        <input
                            class="hidden"
                            type="checkbox"
                            :id="`filter_${filterKey}_${filter.id}`"
                            v-model="selectedFilter[filterKey]"
                            :value="filter.id"
                            @change="applyFilters"
                            :disabled="filter.count === 0 && !selectedFilter[filterKey].includes(filter.id)"
                        >
    
                        <label
                            class=""
                            :for="`filter_${filterKey}_${filter.id}`"
                            :class="`${filterKey}-${filter.id}`"
                        >
                            <div><i class="filter-art-icon" :class="filter.icon"></i></div>
                            <div><span>{{filter.name}}</span></div>
                        </label>
                    </li>
                </template>
            </ul>
        </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import MixinFilters from "../../mixin/MixinFilters"
import _ from 'lodash'
const allowFilters = {
    interestsTags: [11],
    categoriesIds: [7, 55, 33, 1, 23]
}
export default {
    name: "FavoriteFilters",
    mixins: [MixinFilters],
    data: () => ({
         isAnyAnotherSelected: false
    }),
    computed: {
        ...mapGetters(['ApiFilters', 'selectedFilter']),
        filtersListArray(){
            const arr = {}
            for(const key in allowFilters){
                arr[key] = _.pick(this.ApiFilters[key], allowFilters[key])
            }
            return arr
        },
    },
    watch: {
        selectedFilter: {
            deep: true,
            handler(){
                for(const key in this.selectedFilter){
                    const arr = _.difference(this.selectedFilter[key], allowFilters[key])
                    if(arr.length){
                        this.isAnyAnotherSelected = true
                        return
                    }
                }
                this.isAnyAnotherSelected = false
            }
        },
        isAnyAnotherSelected(){
            this.$emit('isAnyAnotherSelected', this.isAnyAnotherSelected)
        }
    }

}
</script>

<style scoped lang="sass">
label
    display: flex
    margin-bottom: 0
    align-items: center
.search-legend-box
    color: #fd4f00
    margin-right: 8px
.filter-icon
    font-size: 1.5em
    font-weight: 400    
    &:before
        font-family: 'IcoMoon', serif
        margin-right: 8px
    &-mustdo
        &:before
            font-family: 'FontAwesome', serif
            content: '\f006'
    &-luxury
        &:before
            content: '\e923'
    
</style>
