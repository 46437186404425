<template>
    <div>
        <div class="find-tour-header-wrapper">
            <div class="find-tour-header">

                <div class="guide-title title-underline">
                    Search Wizard
                </div>
                <div class="search-box">
                    <div class="search-field">
                        <label for="search_field_label" class="search-field-label"><i class="fa fa-search"
                                                                                      aria-hidden="true"></i></label>
                        <input id="search_field_label" placeholder="Find your best tour..." type="text"
                               v-model="searchKey" @keyup="changeSearchKey">
                    </div>
                </div>


                
                
                
            </div>

            <div class="find-tour-filters">
                <div>
                    <div class="card card-body">
                        <div class="find-tour-header" >
                            <div>
                                <OurToursSearchWizardFilters/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="title-underline">
            Our Tours
        </div>

        <div>
            <div class="tour-container-wrapper-header-flex">
                <div>
                    <CounterOfTours />
                </div>
                <div class="tour-container-wrapper-header-controls">

                    <RestFilters />
                    <OrderDropdown />
                </div>
            </div>
        </div>

        <template v-if="requests.tours_wizard_success">
            <OurTours :limit="limit"/>
        </template>
        <template v-else>
            <div class="loading-spinner-wrapper">
                <div class="loading-spinner"></div>
            </div>
        </template>
        <ShowMore @page="showMorePage"/>
    </div>
</template>

<script>
import ShowMore from "./components/TourModules/ShowMore";
import OurTours from "./components/Tours/OurTours";
import {mapGetters} from "vuex";
import OrderDropdown from "./components/Filters/OrderDropdown";
import OurToursSearchWizardFilters from "@/components/Filters/OurToursSearchWizardFilters";
import RestFilters from "@/components/Filters/RestFilters";
import CounterOfTours from "@/components/TourModules/CounterOfTours";

export default {
    name: "ToursApp",
    data: () => ({
        limit: 12,
        searchKey: '',
    }),
    components: {
        CounterOfTours,
        RestFilters,
        OurToursSearchWizardFilters,
        OrderDropdown,
        OurTours,
        ShowMore
    },

    computed: {
        ...mapGetters(['requests', 'selectedFilter']),
        isFilters() {
            return (this.selectedFilter.focus.length > 0 ||
                this.selectedFilter.tags.length > 0 ||
                this.selectedFilter.destinations.length > 0 ||
                this.selectedFilter.itinerary.length > 0 ||
                this.selectedFilter.interestsTags.length > 0 ||
                this.selectedFilter.categoriesIds.length > 0)
        }
    },
    methods: {
        showMorePage(page) {
            this.limit = page
        },
        changeSearchKey() {
            this.$store.commit('commitStringFilter', this.searchKey)
            this.$store.commit('processFiltering')
        },
    },


}
</script>
<style scoped lang="sass">

</style>
