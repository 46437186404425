<template>
    <div class="grid-destination tour-list" >
            <a
               v-for="(tour, index) in theTours"
               class="grid-item tour-item"
               :id="`tour_eras_id_${tour.id}`"
               :key="index"
               :href="tour.route"
               v-show="limit > index"
            >
                <div class="tour-item-flex">
                    <div class="tour-item-img-wrapper" style="">
                        <div class="tour-item-img">
                            <div>
                            <span class="tour-item-src"
                                  :style="`background-image: url(${tour.avatar})`"
                            ></span>
                            </div>
                        </div>

                        <div
                                v-if="inCategory(tour)"
                                class="item-tour-category"
                        >Must Do Tours</div>

                        <div v-if="tour.badges.oblique_strip && tour.badges.oblique_strip.length > 3 && tour.badges.oblique_class" class="item-tour-oblique-strip strip-horizontal item-tour-oblique-strip">
                            <div class="oblique-strip over-block" :class="tour.badges.oblique_class">
                                <div class="strip-label">{{tour.badges.oblique_strip}}</div>
                            </div>
                        </div>

                        <div class="tour-bottom-line ">

                            <div class="product-special-status luxury" v-if="tour.badges.mini_banner > 0">
                                <img src="/images/our_tours/luxoru_product_text.svg" alt="Luxury product">
                            </div>

                            <div class="tour-short-info">

                                <div class="tour-short-info-list">
                                    <div class="short-item" v-if="tour.eras.duration > 0">
                                        <i class="fa fa-clock-o" aria-hidden="true"></i>
                                        <span>{{tour.eras.duration}} hr.</span>
                                    </div>
                                    <div v-if="tour.eras.isPrivate === true" class="short-item">
                                        <i class="fa fa-users" aria-hidden="true"></i>
                                        <span>private</span>
                                    </div>
                                    <div v-if="tour.eras.isGroup === true" class="short-item">
                                        <i class="fa fa-user" aria-hidden="true"></i>
                                        <span>private</span>
                                    </div>
                                </div>
                                <div v-if="tour.eras.isTransferIncluded === true && tour.eras.isDropoffIncluded === true" class="short-alert">Private Driver</div>
                                <div v-if="tour.eras.isTransferIncluded === true && tour.eras.isDropoffIncluded !== true" class="short-alert">One-Way Transfer</div>
                            </div>

                        </div>
                        <div v-if="tour.badges.tour_favorite > 0" class="our_n1_tour">
                            <div class="num"># 1</div>
                            <div class="txt">tour</div>
                        </div>
                    </div>
                    <div class="tour-item-content-wrapper">
                        <h3 class="tour-title">
                            <span>{{tour.eras.name}}</span>
                        </h3>
                        <Rating :tour="tour"/>
     
                        <div class="item-tour-price-wrapper">

                            <div class="item-tour-price">
                                <div class="item-tour-price-text">Starting from: </div>
                                <div class="btn btn-primary button-price">{{tour.eras.advertisedPrice}} {{tour.eras.currency | SymbolFromCurrency}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </a>

    </div>





</template>

<script>
    import _ from 'lodash'
    import MixinFilters from "../../mixin/MixinFilters";
    import TourSortingFilter from "../../filters/TourSortingFilter";
    import {mapGetters} from "vuex";
    import Rating from "@/components/TourModules/Rating";
    import SymbolFromCurrency from "currency-symbol-map";
    export default {
        name: "DestinationTours",
        components: {Rating},
        mixins: [MixinFilters],
        props: {
            limit: {
                default: 99999,
                type: Number
            }
        },
        filters: {
            SymbolFromCurrency
        },
        methods: {
            inCategory(tour){
                return _.intersection(tour.eras.categoriesIds, [1, 39, 7,33, 23]).length > 0
            }
        },
        computed: {
            ...mapGetters(['filteredTours', 'sortBy']),
            theTours(){
                return TourSortingFilter(this.filteredTours, this.sortBy)
            }
        }

    }
</script> 

<style scoped>
    input[type=checkbox]:checked + label .filter-count{
        display: none;
    }
</style>
