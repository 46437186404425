<template>
    <div class="month-picker__container">

        <div class="calendar-body">
            <div
                    v-for="(month, index) in months"
                    :key="index"
                    class="month-picker__month"
                    @click="selectMonth(month)"
                    :class="{
                        'selected-range-first' : isRangeFirst(month),
                        'selected-range-second' : isRangeSecond(month),
                        'selected-range' : inRange(month),
                        'inactive' : month < minDate
                    }"
            >
                <slot>
                    <div class="calendar-cell" >
                        <div class="calendar-cell-text">
                            <div class="calendar-cell-text-month">
                                {{month.format('MMM')}}
                            </div>
                            <div class="calendar-cell-text-year">
                                <em class="small">{{month.format('YYYY')}}</em>
                            </div>
                        </div>
                    </div>
                </slot>
            </div>

        </div>
    </div>

</template>

<script>
    import moment from 'moment'
    export default {
        name: "MonthCalendar",
        props: {
            months: {
                type: Array,
                require: true
            },
            minDate: {
                type: Object,
                default(){
                    return moment().startOf('month')
                }
            }
        },
        data: () => ({
            rangeStart: null,
            rangeEnd: null,
            dateFormat: 'YYYY-MM-DD'
        }),
        computed: {

        },
        methods: {
            selectMonth(month){
                if(!this.rangeStart && !this.rangeEnd){
                    this.rangeStart = month
                }
                else if(this.rangeStart && !this.rangeEnd){
                    this.rangeEnd = month
                }
                else if(this.rangeStart && this.rangeEnd){
                    this.rangeStart = month
                    this.rangeEnd = null
                }
                if(this.rangeStart && this.rangeEnd){
                    const start = this.rangeStart
                    const end = this.rangeEnd
                    if(start > end){
                        this.rangeStart = end
                        this.rangeEnd = start
                    }
                }
                this.$store.commit('createExperience/commitMomentDate', {
                    start:  this.rangeStart.startOf('month'),
                    end: this.rangeEnd ? this.rangeEnd.endOf('month') : this.rangeStart.clone().endOf('month')
                })


            },
            inRange(date){
                return date.isBetween(this.rangeStart, this.rangeEnd)
            },
            isRangeFirst(month){
                return  month.isSame(this.rangeStart, 'month') && month.isSame(this.rangeStart, 'year')
            },
            isRangeSecond(month){
                return  month.isSame(this.rangeEnd, 'month') && month.isSame(this.rangeEnd, 'year')
            }
        }
    }
</script>

<style scoped>

</style>