import _ from 'lodash'
export default function (options, length = 0){
    const size = _.size(options)
    if(length > 0 && size > 0 && size > length ){
        const ordered = _.orderBy(options, ['count'], ['desc'])
        ordered.splice(length)
        return  ordered
    }
    return options
    
}
