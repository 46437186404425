<template>
    <div>
        <div class="item-tour-stars" v-if="tour.num_ratings || tour.questions > 0">
            <div class="review-stars" v-if="tour.num_ratings">
                <div class="star-rating-float"></div>
                <div class="stars-absolute">
                    <span >
                        {{tour.average_rating_round | RatingScore}}
                    </span>
                    <i class="fa fa-star"></i>

                </div>
                <div class="stars-count"><strong>{{ tour.num_ratings }}</strong> Reviews</div>
            </div>
            <div class="comments" v-if="tour.questions > 0"> 
                <strong>{{ tour.questions }}</strong> <span> Questions <br> & Answers </span>
            </div>
        </div>
        <div class="item-tour-stars" v-if="!tour.num_ratings && !tour.questions">
            <span v-if="tour.num_ratings === 0">No review yet.</span>
            <img src="//www.reviews.io/cw-assets/img/reviewsio-logo.svg" alt="Review Io Service" style="width: 65px">
        </div>
    </div>
</template>

<script>
export default {
    name: "Rating",
    props: ['tour'],
    filters: {
        RatingScore(num) {
            return parseFloat(num).toFixed(1)
        }
    }
}
</script>

<style scoped>
</style>
