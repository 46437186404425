<template>
    <section class="td-tours-sections-icons carousel slide" id="carousel_icons_of_attractions" data-ride="carousel" data-interval="false">
        <div class="title">
            <h3 class="title">
                What do you want to see ?
            </h3>
        </div>
        <div class="destinations">
            <div class="title-indicators">{{selectedDestination}}</div>
        </div>
        <div class="icons">
            <div >
                <div >
                    <div class="item">
                        <div class="icons-table">
                            <AttractionIcon :item="item"  v-for="item in icons" :key="item.id" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import {mapGetters} from 'vuex'
    import AttractionIcon from "./AttractionIcon";
    export default {
        name: "CreateYourExperienceIcons3D",
        components: {AttractionIcon},
        computed: {
            ...mapGetters('createExperience', ['icons', 'attractions']),
            selectedDestination() {
                if (this.attractions.length > 0){
                    return this.attractions[0]['city_alias']
                }
                return  null
            }
        }
    }
</script>

<style scoped>

</style>