import Vue from 'vue'
import Vuex from 'vuex'

import search_wizard from "./modules/search_wizard"
import create_your_experience from "./modules/create_your_experience";
import guides from "./modules/guides";


Vue.use(Vuex)

const store = new Vuex.Store({
    modules: {
        search_wizard,
        createExperience: create_your_experience,
        guides
    }
})

export  default store
