<template>
  <div v-if="selectedFilterCount > 0">
      <button @click="resetFilters" class="btn btn-primary-outlined">
          Reset Filters
      </button>
  </div>
</template>

<script>
import MixinFilters from "@/mixin/MixinFilters";
import {mapGetters} from "vuex";

export default {
    name: "RestFilters",
    mixins: [MixinFilters],
    computed: {
        ...mapGetters(['selectedFilter', 'selectedFilterCount']),
        
    }
}
</script>

<style scoped>
    .btn{
        margin: 0 8px;
    }
</style>
