import axios from 'axios'
import _ from "lodash";
export default {
    state: {
        tours_wizard: {},
        ApiFilters: [],
        ApiTours: [],
        selectedFilter: {
            destinations: [],
            interestsTags: [],
            focus: [],
            tags: [],
            categoriesIds: [],
            itinerary: [],
            attractions: [],
        },
        filterList:  ['destinations', 'focus', 'tags',  'interestsTags'],
        filteredTours: [],
        filtersMap: {
            interestsTags: 'Preferences',
            focus: 'Focus',
            tags: 'Group Type',
            categoriesIds: 'Categories',
            itinerary: 'Attractions',
            attractions: 'Attractions',
            destinations: 'Destinations'
        },
        sortBy: {
            key: ['average_rating', 'num_ratings'],
            order: ['desc', 'desc']
        },
        stringFilter: '',
        requests: {
            tours_wizard: false,
            tours_wizard_success: false
        },
        daterange: {
            startDate: null,
            endDate: null,
            ids: [],
        }
    },
    actions: {
        tours_wizard({commit, state}, params){
            if(state.requests.tours_wizard) return true
            state.requests.tours_wizard = true
            return axios.get(process.env.VUE_APP_HOST + '?option=com_rezdyinfo&task=vue.tours_wizard&format=json', {params})
                .then( res => {
                    commit('commitApiTours', res.data.data.Tours)
                    commit('commitApiFilters', {...res.data.data.Filters, destinations: res.data.data.City})
                    commit('commitRequests', {tours_wizard_success: true})
                    commit('commitSelectedFilter', state.selectedFilter)
                    commit('processFiltering')
                    return true
                })
                .catch( () => {
                    commit('commitRequests', {tours_wizard: false, tours_wizard_success: false})
                    return false
                } )
        },
        daterange({commit, state}, params){
            commit('commitDaterange', false)
            commit('processFiltering')
            const ids = state.filteredTours.map( v => v.eras_id).join(',')
            return axios.get(process.env.VUE_APP_HOST + '?option=com_rezdyinfo&task=vue.daterange&format=json', {params: {ids, ...params}})
                .then( res => {
                    commit('commitDaterange', {...params, ids:res.data.data})
                    commit('processFiltering')
                })
                .catch( error => {
                    console.log(error)
                    commit('commitDaterange', {start: null, end: null, ids: []})
                })

        }
    },
    mutations: {
        commitApiTours(state, payload){
            payload.map( (v) => {
                v.average_rating = parseFloat(v.average_rating)
                v.num_ratings = parseInt(v.num_ratings)
                v.questions = parseInt(v.questions)
                return v
            })
            state.ApiTours = payload
        },
        commitApiFilters(state, payload){
            state.ApiFilters = payload
        },
        commitSelectedFilter(state, payload){
            state.selectedFilter = payload
        },

        commitRequests(state, payload){
            state.requests = Object.assign(state.requests, payload)
        },
        commitFilteredTours(state, payload){
            state.filteredTours = payload
        },
        commitOrdering(state, payload){
            state.sortBy = payload
        },
        commitStringFilter(state, payload){
            state.stringFilter = payload
        },
        commitDaterange(state, payload){
            state.daterange = payload
        },
        processFiltering(state){

            let filteredTours = []
            let tourInCategories = {}
            const selectedFilter = _.cloneDeep(state.selectedFilter)
            const filtersList = state.filterList
            const ApiFilters = _.cloneDeep(state.ApiFilters)
            const ApiTours = state.ApiTours
            const stringFilter = state.stringFilter
            const dateRange = state.daterange
            const toursInDaterange = []

            const tourInCategoriesFunction = function(theTour, selectedFilter){
                let inFilters = {}
                inFilters['destinations'] = selectedFilter.destinations.length > 0 ? selectedFilter.destinations.includes(theTour.city_id.toString()) : true
                _.forEach(theTour.Filters , ( filterKeysArray, filterName) => {
                    inFilters[filterName] = selectedFilter[filterName].length  > 0 ? _.intersection(filterKeysArray, selectedFilter[filterName]).length > 0 : true
                })
                return inFilters
            }
            const stringFilterFunction = function(theTour, string) {
                const regex = new RegExp(string, 'i')
                return theTour.eras.name.search(regex)
            }


            if(ApiTours && ApiTours.length > 0){

                _.map(ApiFilters, (v) => {
                    return _.map(v, (v) => {v.count = 0; return v})
                })
                filteredTours = _.filter( ApiTours, (tour) => {

                    tourInCategories[tour.id] = tourInCategoriesFunction(tour, selectedFilter)
                    if(dateRange && dateRange.ids.length > 0 && dateRange.ids.includes(parseInt(tour.eras_id)) === false){
                        return  false
                    }else {
                        toursInDaterange.push(tour.eras_id)
                    }
                    if(stringFilterFunction(tour, stringFilter) === -1 ){
                        return false
                    }
                    let passed = {}
                    _.forEach(filtersList, function ( filterItem) {
                        if(selectedFilter[filterItem].length === 0){
                            passed[filterItem] = true
                            return
                        }
                        passed[filterItem] = _.intersection(tour.Filters[filterItem], selectedFilter[filterItem]).length > 0
                    })
                    return _.every(tourInCategories[tour.id])
                } )
            }

            _.forEach(ApiFilters , (filterArray, filterKey) => {
                _.forEach(tourInCategories, (tour_in_category, tour_in_category_id) => {
                    if(
                        (
                            _.filter(tour_in_category, s => s === false).length === 1 &&
                            tour_in_category[filterKey] === false
                        ) ||
                        (
                            _.every(tour_in_category) &&
                            selectedFilter[filterKey].length === 0
                        )
                    ){
                        try{
                            _.forEach(ApiFilters[filterKey], (fk) => {
                                const curTour = ApiTours.find( v => v.id === tour_in_category_id)

                                if(
                                    curTour.Filters[filterKey].includes(fk.id) &&
                                    toursInDaterange.indexOf(curTour.eras_id) >= 0
                                ) {
                                    fk.count++
                                }
                            })
                        }catch (e) {
                            return e
                        }

                    }
                })
            })
            state.ApiFilters = ApiFilters
            state.filteredTours = filteredTours
            state.selectedFilter = selectedFilter

        }
    },
    getters:{
        ApiTours: s => s.ApiTours,
        ApiFilters: s => s.ApiFilters,
        selectedFilter: s => s.selectedFilter,
        selectedFilterDaterange: s => s.daterange,
        filtersMap: s => s.filtersMap,
        requests: s => s.requests,
        filteredTours: s => s.filteredTours,
        selectedFilterCount: s => _.size(_.flatMapDeep(s.selectedFilter)),
        sortBy: s => s.sortBy
    }
}
